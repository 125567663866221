<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Helios
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Calculadora de gastos
        </b-card-title>
        <b-card-text class="mb-2">
          La informacion ingresada es confidencial.
        </b-card-text>

        <b-form 
          @submit.prevent
          v-if="showExpenses"
        >
          <b-row
          v-for="(expense, indexExpense) in financeData.expenses"
          :key="indexExpense"
          >
            <b-col cols="6">
              <b-form-group
                :label="expense.name"
                label-for="v-first-name"
              >
                <b-form-input
                  id="v-first-name"
                  placeholder=""
                  @keypress="restrictNumber($event)"
                  v-model="expense.expensePrice"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Tipo de gasto"
                label-for="v-first-name"
              >
                <v-select
                  :options="expenseType"
                  label="name"
                  input-id="invoice-data-paymentType"
                  :clearable="false"
                  :reduce="name => name.id"
                  v-model="expense.idAction"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- submit -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click="saveData"
            >
              Calcular
            </b-button>
          </b-row>
          <br>
          <hr class="my-50">
          <b-row v-if="showResults">
            <b-col cols="12">
              <b-card
                no-body
                class="card-apply-job"
              >
                <b-card-body>
                  <!-- Gastos -->
                  <div class="apply-job-package bg-light-warning rounded">
                    <b-row style="display: flex; align-items: center;">       
                      <b-col cols="6">
                        <h4 class="d-inline mr-25">
                          Gastos en la semana
                        </h4>
                      </b-col>
                      <b-col cols="6">
                        <h3 class="d-inline mr-25">
                          $ {{  formatterNumber(financeData.totalExpenses) }}
                        </h3>
                      </b-col>
                    </b-row >
                  </div>
                  <!-- Gano -->
                  <div class="apply-job-package bg-light-primary rounded">
                    <b-row style="display: flex; align-items: center;">       
                      <b-col cols="6">
                        <h4 class="d-inline mr-25">
                          Ganancias en la semana
                        </h4>
                      </b-col>
                      <b-col cols="6">
                        <h3 class="d-inline mr-25">
                          $ {{  formatterNumber(financeData.revenue) }}
                        </h3>
                      </b-col>
                    </b-row >
                  </div>
                  <!-- Utilidad -->
                  <div 
                  :class="'apply-job-package bg-light-'+checkProfit(financeData.profit)+' rounded'"
                  >
                    <b-row style="display: flex; align-items: center;">       
                      <b-col cols="6">
                        <h4 class="d-inline mr-25">
                          Me queda en la semana
                        </h4>
                      </b-col>
                      <b-col cols="6">
                        <h3 class="d-inline mr-25">
                          $ {{  formatterNumber(financeData.profit) }}
                        </h3>
                      </b-col>
                    </b-row >
                  </div>
                </b-card-body>

              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </div>


    <!-- MODAL ALERTA -->
    <b-modal
      id="alertModal"
      title="Alerta"
      ok-title="Voy a revisarlo"
      ok-only
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Faltan datos
        </h4>
        <div class="alert-body font-medium-2">
          <p>Favor de llenar todos los campos.</p>
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
  BRow, BCol, BAlert, BCardBody,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import utils from '../../utils/utils.js';



export default {
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BAlert,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      expenseType: [],
      expenses: [],
      financeData: {
        totalExpenses: 0,
        revenue: 0,
        profit: 0,
        profitKG: 1,
        avg: 1387.5,
        expenses: []
      },
      data:[],
      showResults: false,
      showExpenses: false,
    }
  },
  created() {
    this.getExpenses();
    this.getAction();
    this.getInventoryData();
  },  
  methods: {
    getExpenses() {
      this.$axios.get(this.$services + 'expenses/get_expenses', { 
        params: {
          type: 'calculator'
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.expenses = res.data.data;
          this.getLastCalculate();
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {});
    },
    getAction() {
      this.$axios.get(this.$services + 'general/get_actions', { 
        params: {
          type: 'calculator'
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.expenseType = res.data.data;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {});
    },
    getInventoryData() {
      this.$axios.get(this.$services + 'general/get_data_calculator').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.data = res.data.data;
          this.financeData.avg = this.data.average;
          this.financeData.profitKG = this.data.profit;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {});
    },
    getLastCalculate() {
      this.$axios.get(this.$services + 'general/get_calculator_expenses').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          let obj = {};
          if (res.data.data.length > 0) { // Hay datos
            res.data.data.forEach((element, index) => {
              if (this.expenses[index].id == element.idExpense) {
                obj = {
                  idAction: element.idAction,
                  expensePrice: element.amount,
                  id: this.expenses[index].id,
                  name: this.expenses[index].name
                };
                this.financeData.expenses.push(obj);
              }
            });
          } else {
            let idAction = (this.expenseType.find(expense => expense.name == 'DIARIO')).id;
            this.expenses.forEach((element, index) => {
                obj = {
                  idAction: idAction,
                  expensePrice: null,
                  id: this.expenses[index].id,
                  name: this.expenses[index].name
                };
                this.financeData.expenses.push(obj);
            });
          }
          this.showExpenses = true;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {});
    },
    createOperation(value) {
      this.$axios.post(this.$services+'general/calculate_profit', {
        financeData: value,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {});
    },
    saveData() {
      this.calculate();
    },
    calculate() {

      this.financeData.totalExpenses = 0;
      // Get prices
      this.financeData.expenses.forEach((element, index) => {
        let action = element.idAction == (this.expenseType.find(expense => expense.name == 'DIARIO')).id ? 6 : 1;
        let value = element.expensePrice == null ? 0 : element.expensePrice;
        this.financeData.totalExpenses += value * action;
      });
      
      // Generate prices

      this.financeData.revenue = (this.financeData.profitKG * this.financeData.avg)*6;

      this.financeData.profit = this.financeData.revenue - this.financeData.totalExpenses;

      this.showResults = true;

      window.scrollTo(0, document.body.scrollHeight);

      this.createOperation(this.financeData)
    },
    restrictNumber(event) { // Sólo números pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
        return;
      }
      this.byPassRouteLeave = false;
    },
    formatterNumber(value){
      return utils.numberWithCommas(value)
    },
    checkProfit(profit) {

      if (profit < 1) {
        return 'danger';
      } else { // Not warnign
        return 'success';
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
